import { Experiment } from '@lojinha/experiment'
import { GetPageContentQuery } from '@lojinha/palantir'
import { captureException } from '@lojinha/sentry'

type ContainerNodes = NonNullable<
  GetPageContentQuery['page']
>['components']['nodes']

type VisibilityCondition = 'control' | 'variant'

export type KitPricingExperimentContainer = {
  containerId: string
  visibility: VisibilityCondition
}

function processComponentNodes(
  nodes: ContainerNodes,
  containersDefinitionMap: Map<string, VisibilityCondition>,
  visibility: VisibilityCondition
): ContainerNodes {
  return nodes.filter(container => {
    if (container.__typename !== 'CMSContainer') {
      return true
    }

    const visibilityCondition = containersDefinitionMap.get(container.id)
    if (!visibilityCondition) {
      return true
    }

    return visibilityCondition === visibility
  })
}

export function applyKitPricingExperimentChanges(
  page: GetPageContentQuery['page'],
  containersDefinition: KitPricingExperimentContainer[],
  isVariant: boolean
): GetPageContentQuery['page'] {
  if (!page?.components.nodes.length) {
    return page
  }

  const containersDefinitionMap = new Map(
    containersDefinition.map(container => [
      container.containerId,
      container.visibility,
    ])
  )

  const filteredNodes = processComponentNodes(
    page.components.nodes,
    containersDefinitionMap,
    isVariant ? 'variant' : 'control'
  )

  return {
    ...page,
    components: {
      ...page.components,
      totalCount: filteredNodes.length,
      nodes: filteredNodes,
    },
  }
}

export function isKitPricingExperiment(_experiment: Experiment | undefined) {
  return false
}

export function parseJSONConfig(
  str: string | null | undefined
): KitPricingExperimentContainer[] {
  if (!str) {
    return []
  }

  try {
    const cfg = JSON.parse(str)
    if (Array.isArray(cfg)) {
      return cfg
    }
  } catch (err) {
    captureException(err as Error)
  }
  return []
}
